
export const data = [
  
    {
      id:1,
      image:"./video/giris.mp4",
    },
    {
     id:2,
     image:"./video/yazilimvideo.mp4",
    },
];

export const mobilgif =[
  {
    id:1,
    video:"/video/uygulamagiff.mp4"
  }
]
export const buyOnCarousel = [
  {
    id: 1,
    image:"/image/yazilim/buycar1.jpg",
  },
  {
    id: 2,
    image:"/image/yazilim/bobcar.jpg",
  },
  {
    id: 3,
    image:"/image/yazilim/car3.jpg",
  }
];


export const board =[
  {
    id:1,
    image:"/image/referans/ajet.png",
    title:"AJet",
  },
  {
    id:2,
    image:"/image/referans/pegasus.png",
    title:"Peagsus",
  },
  {
    id:3,
    image:"/image/referans/mga.png",
    title:"Mavi Gök Airlines",
  },
  {
    id:4,
    image:"/image/referans/freebird.png",
    title:"Freebird Airlines",
  },
];










